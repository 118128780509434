import { graphql, useStaticQuery } from 'gatsby';

interface Metadata {
  title: string;
  description: string;
  image: string;
  twitterUsername: string;
  author: string;
  siteUrl: string;
}

export const useSiteMetadata = (): Metadata => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
          twitterUsername
          author
          siteUrl
        }
      }
    }
  `);

  return data.site.siteMetadata;
};
