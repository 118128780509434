import * as React from 'react';
import { useSiteMetadata } from '../../hooks/UseSiteMetadata';

interface SEOProps {
  title?: string;
  description?: string;
  pathname?: string;
  children?: React.ReactNode;
}

const SEO: React.FC<SEOProps> = ({ title, description, pathname, children }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: siteUrl + (image || image),
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="fb:app_id" content="472714070256667" />
      <link rel="canonical" href="https://www.lachamba.app" />
      <meta
        name="keywords"
        content="Chamba, Chamba App, Chamba Business, Trabajo, Jale, Jobs, Staff"
      />
      <script type="application/ld+json">
        {`{
          "@context": "http://schema.org",
          "@type": "SoftwareApplication",
          "name": "Chamba App",
          "image": "https://chamba-bucket.s3.amazonaws.com/Defaults/landing-page/SEO-logo.jpg",
          "url": "https://lachamba.app/",
          "author": {
            "@type": "Person",
            "name": "Chamba Inc"
          },
          "publisher": {
            "@type": "Organization",
            "name": "Chamba Inc"
          },
          "applicationCategory": "Productivity",
          "downloadUrl": [
            "https://play.google.com/store/apps/details?id=com.chambaapp",
            "https://apps.apple.com/co/app/chamba-inc/id1510648714"
          ],
          "operatingSystem": "Android / iOS"
        }`}
      </script>
      {children}
    </>
  );
};

export default SEO;
