import * as React from 'react';
import { graphql, HeadFC } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import SEO from '../components/SEO';
import Header from '../components/Header';

const BlogPostTemplate: React.FC<any> = ({ data }) => {
  const post = data.mdx;
  return (
    <React.Fragment>
      <Header />
      <div className="w-full flex justify-center">
        <div className="w-full md:w-1/2 px-10 md:px-0 my-20">
          <article id="content" className="">
            <h1 className="text-primary text-4xl font-bold mb-6">{post.frontmatter.title}</h1>
            <h2 className="italic mb-10">{post.frontmatter.date}</h2>
            <section itemProp="articleBody" className="prose prose-xl mt-8 mx-auto">
              <MDXRenderer>{post.body}</MDXRenderer>
            </section>
          </article>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlogPostTemplate;

export const Head: HeadFC<any> = ({ data }) => {
  const post = data.mdx;
  return <SEO title={post.frontmatter.title} />;
};

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
